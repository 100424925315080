import React from "react"
import { FaAddressBook, FaEnvelope, FaEraser, FaPhone } from "react-icons/fa"
import Title from "../Headings/Title"

const ContactUs = () => (
  <section className="contactus-section">
    <Title>Contact...</Title>
    <div className="container py-5">
      <div className="row justify-content-center align-items-center">
        <div className="col-lg-4">
          <div
            className="card p-2 text-center shadow mb-4 border-primary"
            style={{
              backgroundColor:
                "#" + Math.random().toString(16).slice(-6) + "10",
            }}
          >
            <div className="row">
              <div className="col-2 p-4">
                <h1>
                  <FaAddressBook />
                </h1>
              </div>
              <div className="col-10">
                <h4 className="text-primary ">Our Address</h4>
                <p>
                  A108 Adam Street <br /> New York, NY 535022
                </p>
              </div>
            </div>
          </div>

          <div
            className="card p-2 text-center shadow mb-4 border-primary"
            style={{
              backgroundColor:
                "#" + Math.random().toString(16).slice(-6) + "10",
            }}
          >
            <div className="row">
              <div className="col-2 p-4">
                <h1>
                  <FaEnvelope />
                </h1>
              </div>
              <div className="col-10">
                <h4 className="text-primary">Email Us</h4>
                <p>
                  info@example.com
                  <br />
                  contact@example.com
                </p>
              </div>
            </div>
          </div>

          <div
            className="card p-2 text-center shadow mb-4 border-primary"
            style={{
              backgroundColor:
                "#" + Math.random().toString(16).slice(-6) + "10",
            }}
          >
            <div className="row">
              <div className="col-2 p-4">
                <h1>
                  <FaPhone />
                </h1>
              </div>
              <div className="col-10">
                <h4 className="text-primary">Call Us</h4>
                <p>
                  +1 5589 55488 55
                  <br />
                  +1 6678 254445 41
                </p>
              </div>
            </div>
          </div>
        </div>

        <div className="col-lg-7 col-md-12">
          <form
            method="POST"
            role="presentation"
            netlify-honeypot="bot-field"
            data-netlify="true"
            name="contact"
            className="card p-4 shadow mb-4 border-primary"
            style={{
              backgroundColor:
                "#" + Math.random().toString(16).slice(-6) + "10",
            }}
          >
            <input type="hidden" name="bot-field" />
            <input type="hidden" name="form-name" value="contact" />
            <div className="form-row">
              <div className="col-lg-12 form-group mb-2">
                <input
                  type="text"
                  name="name"
                  className="form-control"
                  id="name"
                  placeholder="Your Name"
                />
              </div>
              <div className="col-lg-12 form-group mb-2">
                <input
                  type="email"
                  className="form-control"
                  name="email"
                  id="email"
                  placeholder="Your Email"
                />
              </div>
            </div>
            <div className="col-lg-12 form-group mb-2">
              <input
                type="text"
                className="form-control"
                name="subject"
                id="subject"
                placeholder="Subject"
              />
            </div>
            <div className="col-lg-12 form-group mb-4">
              <textarea
                className="form-control"
                name="message"
                id="message"
                rows="8"
                placeholder="Message"
              ></textarea>
            </div>
            <div className="text-center">
              <div
                className="btn-group btn-group-lg text-decoration-none mb-4"
                role="group"
                aria-label="Send"
              >
                <button type="submit" className="btn btn-primary btn-lg shadow">
                  <span className="mx-4">Send Message</span>
                </button>
                <button type="reset" className="btn btn-light btn-lg shadow">
                  <FaEraser />
                </button>
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
  </section>
)

export default ContactUs
