import React from "react"
import ContactUs from "../components/ContactUs/ContactUs"
import Layout from "../components/Layout/Layout"
import Seo from "../components/Seo/Seo"

const ContactPage = () => (
  <Layout>
    <Seo title="Contact" />
    <ContactUs />
  </Layout>
)

export default ContactPage
